@font-face {
  font-family: 'playfair_display_web';
  src: url('playfairdisplay-bold-webfont.woff2') format('woff2'),
	   url('playfairdisplay-bold-webfont.woff') format('woff'),
	   url('playfairdisplay-bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
 }

 @font-face {
  font-family: 'playfair_display_web';
  src: url('playfairdisplay-regular-webfont.woff2') format('woff2'),
	   url('playfairdisplay-regular-webfont.woff') format('woff'),
	   url('playfairdisplay-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
 }
