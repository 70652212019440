/* roboto-100 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
  url('./files/roboto-v18-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-100.woff') format('woff') /* Modern Browsers */
}
/* roboto-100italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
  url('./files/roboto-v18-latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-100italic.woff') format('woff') /* Modern Browsers */
}
/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
  url('./files/roboto-v18-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-300.woff') format('woff') /* Modern Browsers */
}
/* roboto-300italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('./files/roboto-v18-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-300italic.woff') format('woff') /* Modern Browsers */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
  url('./files/roboto-v18-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-regular.woff') format('woff') /* Modern Browsers */
}
/* roboto-italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('./files/roboto-v18-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-italic.woff') format('woff') /* Modern Browsers */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('./files/roboto-v18-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-500.woff') format('woff') /* Modern Browsers */
}
/* roboto-500italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
  url('./files/roboto-v18-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-500italic.woff') format('woff') /* Modern Browsers */
}
/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('./files/roboto-v18-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-700.woff') format('woff') /* Modern Browsers */
}
/* roboto-700italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('./files/roboto-v18-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-700italic.woff') format('woff') /* Modern Browsers */
}
/* roboto-900 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'),
  url('./files/roboto-v18-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-900.woff') format('woff') /* Modern Browsers */
}
/* roboto-900italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
  url('./files/roboto-v18-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./files/roboto-v18-latin-ext_latin-900italic.woff') format('woff') /* Modern Browsers */
}
