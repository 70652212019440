/* Only styles for root components that cannot be achieved using Evergreen should be put in here */

html
 {
  height: 100%;
  min-height: 100%;
}
body {
  min-height: 100%;
  height: 100%;
  background: #f5f5f5 !important;
}
#root {
    height: 100%;
}
